<template>
  <v-menu
    location="bottom"
    min-width="200px"
  >
    <template #activator="{ props }">
      <g-btn
        metric="app/account"
        color="grey"
        data-cy="account-menu-btn"
        v-bind="props"
        icon
      >
        <v-icon>
          mdi-account
        </v-icon>
      </g-btn>
    </template>
    <v-card>
      <v-list class="py-0">
        <v-list-item
          to="/profile"
          title="Profile"
          prepend-icon="mdi-account"
        />
        <v-divider />
        <v-list-item
          v-if="admin || staff"
          href="/admin"
          title="Admin"
          prepend-icon="mdi-account-cog"
        />
        <v-divider />
        <v-list-item
          prepend-icon="mdi-restart"
          title="Restart Tour"
          @click="resetTour"
        />
        <v-divider />
        <v-list-item
          title="Logout"
          prepend-icon="mdi-exit-run"
          @click="logout"
        />
        <v-divider />
        <v-list-item v-if="admin && git">
          <v-card-text
            data-cy="account-menu-git"
            class="text-left"
          >
            Version: {{ git.version }}<br />
            Branch: {{ git.branch === "master" ? "main" : git.branch }}<br />
            Commit: {{ git.shortid }}<br />
            {{ new Date(git.time * 1000).toLocaleString() }}
          </v-card-text>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import axios from 'axios'
import { clearAllIntoComplete } from '../../introUtil'
import { removeCookie } from "@/views/common"
export default {
  props: ["admin", "git", "staff"],
  methods: {
    logout() {
      
      axios({
        url: "/integrations/services/auth/logout/",
        method: "GET",
        credentials: "include"
      })
      removeCookie("sessionid")
      window.location.search = ""
      window.location.pathname = "/"
    },
    resetTour() {
      clearAllIntoComplete()
      this.$router.push("/dashboard").catch((error) => {
        if (error.name == "NavigationDuplicated")
          window.location.reload()
      })
    },
  }
}

</script>
